<template>

  <div>

    <!-- Table Container Card -->
    <b-card
        no-body
        class="mb-0"
    >

      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
              cols="12"
              md="6"
              class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>显示</label>
            <v-select
                v-model="limit"
                :options="limitOptions"
                :clearable="false"
                class="per-page-selector d-inline-block mx-50"
            />
            <label>条</label>
          </b-col>

          <!-- Search -->
          <b-col
              cols="12"
              md="12"
              class=" demo-inline-spacing d-flex align-items-center justify-content-end"
          >

            <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="outline-primary"
                @click="pushBBMallBatch"
            >
              <feather-icon
                  icon="PlusIcon"
                  class="mr-50"
              />
              <span class="align-middle">批量推送</span>
            </b-button>

            <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="outline-primary"
                @click="advanced_search"
            >
              <feather-icon
                  icon="PlusIcon"
                  class="mr-50"
              />
              <span class="align-middle">高级搜索</span>
            </b-button>
          </b-col>
        </b-row>

        <b-form
            @submit.prevent="searchByCondition"
            @reset.prevent="resetCondition"
        >
          <b-card
              v-show="advanced_search_modal"
          >
            <b-row>
              <b-col
                  cols="12"
                  md="3"
              >
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="商品ID"
                    label-for="product_id"
                    label-size="sm"
                >
                  <b-form-input
                      v-model="product_id"
                      class="d-inline-block mr-1"
                      size="sm"
                  />
                </b-form-group>
              </b-col>
              <b-col
                  cols="12"
                  md="3"
              >
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="物料编码"
                    label-for="product_code"
                    label-size="sm"
                >
                  <b-form-input
                      v-model="product_code"
                      class="d-inline-block mr-1"
                      size="sm"
                  />
                </b-form-group>
              </b-col>

              <b-col
                  cols="12"
                  md="3"
              >
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="物料名称"
                    label-for="product_name"
                    label-size="sm"
                >
                  <b-form-input
                      v-model="product_name"
                      class="d-inline-block mr-1"
                      size="sm"
                  />
                </b-form-group>
              </b-col>

              <b-col
                  cols="12"
                  md="3"
              >
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="规格型号"
                    label-for="product_specification"
                    label-size="sm"
                >
                  <b-form-input
                      v-model="product_specification"
                      class="d-inline-block mr-1"
                      size="sm"
                  />
                </b-form-group>
              </b-col>

              <b-col
                  cols="12"
                  md="3"
              >
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="物料状态"
                    label-for="allot_type"
                    label-size="sm"
                >
                  <v-select
                      id="allot_type"
                      :options="getCodeOptions('product_status')"
                      :clearable="false"
                      v-model="product_status_label"
                      class="select-size-sm"
                      @input="changeProductStatus($event)"
                      placeholder="选择物料状态"
                  />
                </b-form-group>
              </b-col>

              <b-col
                  cols="12"
                  md="3"
              >
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="单箱标准数量"
                    label-for="product_boxQuantity"
                    label-size="sm"
                >
                  <b-form-input
                      v-model="product_boxQuantity"
                      class="d-inline-block mr-1"
                      size="sm"
                      type="number"
                  />
                </b-form-group>
              </b-col>


              <b-col cols="12" md="3">
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="分组"
                    label-for="category"
                    label-size="sm"
                >
                  <b-form-input
                      id="chooseId"
                      size="sm"
                      readonly
                      placeholder="请选择分类"
                      v-b-modal.modal-select-category
                      v-model="product_category_name"
                      class="d-inline-block mr-1"
                  />
                </b-form-group>
              </b-col>

              <b-modal
                  id="modal-select-category"
                  no-close-on-backdrop
                  ok-title="确认"
                  cancel-title="取消"
                  centered
                  @ok="onSelectCategory"
                  size="lg"
                  title="分组选择"
              >
                <category-select
                    :categoryOptions="category_options"
                    :level="level"
                    ref="categorySel">
                </category-select>

              </b-modal>

              <b-col cols="12" md="3">
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="品牌"
                    label-for="brand_id"
                    label-size="sm"
                    class="mb-1"
                >
                  <v-select
                      id="brand_id"
                      :options="brandArray"
                      :clearable="false"
                      v-model="product_brandName"
                      @input="changeBrandId($event)"
                      placeholder="请选择品牌"
                      class="select-size-sm"
                  />
                </b-form-group>
              </b-col>

              <b-col cols="12" md="3">
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="默认税率"
                    label-for="tax_rate"
                    label-size="sm"
                    class="mb-1"
                >
                  <v-select
                      id="tax_rate"
                      :options="getCodeOptions('tax_rate')"
                      :clearable="false"
                      v-model="product_taxRate_label"
                      class="select-size-sm"
                      @input="changeSelect('tax_rate', $event)"
                      placeholder="请选择默认税率"
                  />
                </b-form-group>
              </b-col>

              <b-col cols="12" md="3">
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="保质期单位"
                    label-for="guarantee_period_unit"
                    label-size="sm"
                    class="mb-1"
                >
                  <v-select
                      id="guarantee_period_unit"
                      :options="getCodeOptions('product_unit_period')"
                      :clearable="false"
                      v-model="product_guaranteePeriodUnitLabel"
                      @input="changeSelect('guarantee_period_unit',$event)"
                      class="select-size-sm"
                      placeholder="请选择保质期单位"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="12" md="3">
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="标签"
                    label-for="company_owner"
                    label-size="sm"
                    class="mb-1"
                >
                  <b-form-input
                      id="product_label"
                      size="sm"
                      v-model="product_label_name"
                      @click="showProductLabel()"
                      readonly
                  />
                </b-form-group>
              </b-col>


              <b-col cols="19" md="3">
                <b-form-group
                    label-cols="3"
                    label-cols-lg="20"
                    label="保质期"
                    label-for="guarantee_period"
                    label-size="sm"
                    class="mb-1"
                    v-if="product_guaranteePeriodUnitLabel.length > 0 ? true: false"
                >
                  最小值:
                  <b-form-input
                      v-model="min_guarantee_period"
                      size="sm"
                      type="number"
                  />
                  最大值:
                  <b-form-input
                      v-model="max_guarantee_period"
                      size="sm"
                      type="number"
                  />
                </b-form-group>
              </b-col>
              <b-col
                  cols="12"
                  md="12"
                  class=" demo-inline-spacing d-flex align-items-center justify-content-end"
              >
                <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline-primary"
                    type="submit"
                >
                  <span class="align-right">查询</span>
                </b-button>
                <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline-primary"
                    type="reset"
                >
                  <span class="align-right">重置</span>
                </b-button>
                <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline-primary"
                    @click="advanced_search"
                >
                  <span class="align-right">关闭</span>
                </b-button>
              </b-col>
            </b-row>
          </b-card>
        </b-form>

      </div>

      <b-table
          ref="refListTable"
          class="position-relative"
          :items="searchList"
          responsive
          :fields="tableColumns"
          primary-key="id"
          :sort-by.sync="orderBy"
          show-empty
          empty-text="未找到记录"
          :sort-desc.sync="isSortDirDesc"
          v-model="table"
      >

        <template v-slot:head(#)="data">
          <b-form-checkbox v-model="allchecked" @change="toggleAll" :indeterminate="indeterminate">
          </b-form-checkbox>
        </template>
        <template v-slot:cell(#)="row">
          <b-form-checkbox @change="checkboxClick(row.item)" v-model="isCheckedObj['index'+row.item.id]">
          </b-form-checkbox>
        </template>

        <!-- Columns -->
        <template #cell(product_id)="data">
          #{{ data.item.product_id }}
        </template>

        <template #cell(status)="data">
          <b-badge
              pill
              :variant="`light-${getCodeColor('product_status', data.item.status)}`"
          >
            {{ getCodeLabel('product_status', data.item.status) }}
          </b-badge>
        </template>


        <!--        product_attribute-->
        <template #cell(product_attribute)="data">
          {{ getCodeLabel('product_attribute', data.item.product_attribute) }}
        </template>

        <template #cell(guarantee_period_unit)="data">
          {{ getCodeLabel('product_unit_period', data.item.guarantee_period_unit) }}
        </template>

        <!--        product_unit-->
        <template #cell(product_unit)="data">
          {{ getCodeLabel('product_unit', data.item.product_unit) }}
        </template>

        <!-- Column: State -->
        <template #cell(state)="data">
          <b-link
              @click="changeState(data.item)"
          >
            <b-badge
                pill
                :variant="`light-${getCodeColor('state', data.item.state)}`"
            >
              {{ getCodeLabel('state', data.item.state) }}
            </b-badge>
          </b-link>
        </template>

        <template #cell(stockStr)="data">
          <b-link
              @click="showStock(data.item)"
              class="font-weight-bold d-block text-nowrap"
          >
            {{ data.item.stockStr }}
          </b-link>
        </template>

        <template #cell(product_label_name_ext)="data">
          <b-link
              @click="showProductLabelExt(data.item)"
              class="font-weight-bold d-block text-nowrap"
          >
            {{ data.item.ext.name.label_name }}
          </b-link>
        </template>

        <template #cell(tax_rate)="data">
          {{ data.item.tax_rate * 100 }}%
        </template>

        <!--   id/skuid     -->
        <template #cell(bbm_sku_id)="data">
          <div v-if="data.item.bbm_item_id!=null">#{{ data.item.bbm_item_id }}</div>
          <div v-if="data.item.bbm_sku_id!=null">#{{ data.item.bbm_sku_id }}</div>
          <!--推送-->
          <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="outline-success"
              size="sm"
              v-if="data.item.bbm_item_id ==null &&data.item.bbm_sku_id==null"
              @click="pushBBMall(data.item.id)"
          >
            <span class="align-middle">推送</span>
          </b-button>
        </template>

        <!--   首图     -->
        <template #cell(bbm_image_id)="data">

          <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="outline-primary"
              size="sm"
              v-if="data.item.bbm_image == null"
              @click="addFirstPicture(data.item)"
          >
            <span class="align-middle">上传</span>
          </b-button>

          <b-img
              v-if="data.item.bbm_image != null"
              @click="addFirstPicture(data.item)"
              :src="url +'/'+data.item.bbm_image"
              width="80"
              height="80"
          />
        </template>


        <!--   详情    -->
        <template #cell(bbm_details)="data">

          <b-dropdown variant="link" no-caret>
            <template #button-content>
              <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="align-middle text-body"
              />
            </template>

            <div v-if="data.item.bbm_details == null">
              <b-dropdown-item>
                <feather-icon icon="Edit2Icon"/>
                <span class="align-middle ml-50" @click="addDetails(data.item)">上传详情图</span>
              </b-dropdown-item>
            </div>

            <div v-if="data.item.bbm_details != null">
              <b-dropdown-item>
                <feather-icon icon="EditIcon"/>
                <span class="align-middle ml-50" @click="addDetails(data.item)">编辑详情图</span>
              </b-dropdown-item>
            </div>


          </b-dropdown>

        </template>
      </b-table>

      <b-modal
          id="modal-stock-log"
          ok-only
          centered
          size="xl"
          title="库存日志"
      >
        <stock-log-list :productid="id" :stockid="''"></stock-log-list>
      </b-modal>

      <b-modal
          id="modal-product-log"
          hide-footer
          centered
          size="xl"
          title="修改日志"
      >
        <modify-log-list :objectTypeProduct="1" :productid="id"></modify-log-list>
      </b-modal>

      <div class="mx-2 mb-2">
        <b-row>

          <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">显示 {{ dataMeta.from }} - {{ dataMeta.to }} / {{ dataMeta.of }} 记录</span>
          </b-col>
          <!-- Pagination -->
          <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
                v-model="start"
                :total-rows="listTotals"
                :per-page="limit"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>

      <b-modal
          id="modal-single"
          ok-only
          ok-title="确认"
          @ok="onSelectWHFromPeople"
          cancel-title="取消"
          centered
          size="lg"
          :title="'请选择'"
          ref="productLabel"
      >
        <ProductLabelList
            ref="whSelect" v-on:table="fromChildren($event,'from')"
        >
        </ProductLabelList>
      </b-modal>

      <b-modal
          id="modal-single"
          ok-only
          ok-title="确认"
          @ok="selectProductLabel"
          cancel-title="取消"
          centered
          size="lg"
          :title="'请选择标签'"
          ref="productLabelExt"
      >
        <ProductLabelList
            ref="labelSelect" v-on:table="fromChildren($event,'from')"
        >
        </ProductLabelList>
      </b-modal>

      <b-modal
          id="stockModal"
          ref="stockModal"
          ok-only
          ok-title="确认"
          @hide="hideModal"
          cancel-title="取消"
          centered
          size="xl"
          title="库存列表"
      >
        <stock-show-modal
            ref="stockShowModal" :productId="productId">
        </stock-show-modal>
      </b-modal>


      <!-- 复制行 模态框 -->
      <b-modal
          id="copyRowDataModal"
          ref="copyRowDataModal"
          centered
          size="lg"
          title="复制行"
          hide-footer
          @hide="hideCopyRowModal"
      >
        <b-form-group
            label-cols="5"
            label-cols-lg="3"
            label="商品编码(69码)"
            label-for="code"
            label-size="sm"
            class="mb-1 required"
        >
          <!--  v-model="product.code" -->
          <div>
            <b-form-input
                id="code"
                size="sm"
                key="productCode"
                @keyup.native="btKeyUp"
                v-model="productCode"
                onkeyup="value=value.replace(/[\W]/g,'') "
                onbeforepaste="clipboardData.setData('text',clipboardData.getData('text').replace(/[^\d]/g,''))"
            />
          </div>
        </b-form-group>
        <b-form-group
            label-cols="5"
            label-cols-lg="3"
            label="商品名称"
            label-for="productName"
            label-size="sm"
            class="mb-1 required"
        >
          <div>
            <b-form-input
                id="productName"
                size="sm"
                v-model="productName"
            />
          </div>
        </b-form-group>
        <b-form-group
            label-cols="5"
            label-cols-lg="3"
            label="商品规格"
            label-for="specification"
            label-size="sm"
            class="mb-1 required"
        >
          <div>
            <b-form-input
                id="specification"
                size="sm"
                v-model="specification"
            />
          </div>
        </b-form-group>
        <b-form-group
            label-cols="5"
            label-cols-lg="3"
            label="基本单位"
            label-for="product_unit"
            label-size="sm"
            class="mb-1 required"
        >
          <div>
            <v-select
                id="product_unit"
                :options="getCodeOptions('product_unit')"
                :clearable="true"
                v-model="productUnit"
                :reduce="option => option.value"
                class="select-size-sm"
            />
          </div>
        </b-form-group>
        <b-form-group
            label-cols="5"
            label-cols-lg="3"
            label="单箱标准数量"
            label-for="box_quantity"
            label-size="sm"
            class="mb-1 required"
        >
          <div>
            <b-form-input
                id="boxQuantity"
                size="sm"
                type="number"
                v-model="boxQuantity"
                @mousewheel.prevent
            />
          </div>
        </b-form-group>
        <b-button
            size="sm"
            variant="outline-primary" @click="submitCopyRowData" style="float: right">确定
        </b-button>
      </b-modal>

      <!--      修改箱规-->
      <b-modal
          id="productBoxModal"
          ref="productBoxModal"
          ok-only
          ok-title="关闭"
          centered
          :size="showBoxModal==='product'?'lg':'xl'"
          title="修改箱规"
      >
        <div v-if="showBoxModal==='product'">
          <b-form-group
              label-cols="3"
              label-cols-lg="3"
              label="物料名称:"
              label-for="name"
              label-size="sm"
              class="mb-1"
          >
            <b-form-input
                size="sm"
                v-model="boxProduct.name"
                readonly
            />
          </b-form-group>
          <b-form-group
              label-cols="3"
              label-cols-lg="3"
              label="修改前商品箱规:"
              label-for="product_id"
              label-size="sm"
              class="mb-1"
          >
            <b-form-input
                size="sm"
                v-model="boxProduct.box_quantity"
                readonly
            />
          </b-form-group>

          <b-form-group
              label-cols="3"
              label-cols-lg="3"
              label="修改后商品箱规:"
              label-for="product_id"
              label-size="sm"
              class="mb-1 required"
          >
            <b-form-input
                size="sm"
                v-model="newBoxQuantity"
                type="number"
            />
          </b-form-group>

          <b-row>
            <b-col cols="10"></b-col>
            <b-col
                cols="2"
            >
              <b-button
                  variant="primary"
                  class="mr-1"
                  @click="saveProductBox"
              >
                保存
              </b-button>
            </b-col>
          </b-row>

        </div>

        <product-box-edit v-if="showBoxModal==='productBox'" :product="boxProduct" v-on:change="closeModal">

        </product-box-edit>


      </b-modal>


      <b-modal
          id="addFirstPicture"
          ref="addFirstPicture"
          ok-only
          ok-title="确认"
          @ok="onAddPicture"
          centered
          size="lg"
          title="请上传首图"
      >
        <attachment-upload @change="uploadPicture"
                           :id="this.bbm_image_id"
                           object_type="product">

        </attachment-upload>
      </b-modal>

      <b-modal
          id="addDetails"
          ref="addDetails"
          ok-only
          ok-title="确认"
          @ok="onAddDetails"
          centered
          size="lg"
          title="请上传详情图"
      >
        <attachment-upload theme="files"
                           @change="uploadDetails"
                           :id="this.bbm_details"
                           object_type="product">

        </attachment-upload>
      </b-modal>

    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import {ref, onUnmounted} from '@vue/composition-api'
import {avatarText, getCode, getCodeColor, getCodeLabel, getCodeOptions} from '@core/utils/filter'
import productUseList from './productUseList'
import productStore from '../productStore'
import StockShowModal from '@/views/apps/stock/stock-show-modal/StockShowModal'
import {getUserData} from '@/auth/utils'
import StockLogList from "@/views/apps/stocklog/StockLogList";

import Image from "@/views/components/image/Image";
import {useToast} from "vue-toastification/composition";
import Ripple from 'vue-ripple-directive'
import CategorySelect from '@/views/apps/category/category-modal/CategorySelect'
import brandStore from "@/views/apps/brand/brandStore";
import instance from "@/libs/axios";
import AttachmentUpload from '@/views/apps/attachment/AttachmentUpload'
import ProductLabelList from "@/views/apps/productlabel/productlabel_modal/ProductLabelList";
import ProductBoxEdit from "@/views/apps/productbox/ProductBoxEdit";
import ModifyLogList from "@/views/apps/modifylog/ModifyLogList";
import modifylogStore from "@/views/apps/modifylog/modifylogStore";


export default {
  components: {
    ProductBoxEdit,
    //Image,
    ProductLabelList,
    AttachmentUpload,
    StockLogList,
    StockShowModal,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect,
    CategorySelect,
    ModifyLogList,
  },
  directives: {
    Ripple,
  },
  watch: {
    checked: {
      handler(newVal, oldVal) {
        if (newVal.length == 0) {
          this.indeterminate = false
          this.allchecked = false
        } else if (newVal.length == this.table.length) {
          this.indeterminate = false
          this.allchecked = true
        } else {
          this.indeterminate = true
          this.allchecked = false
        }
        this.$forceUpdate()
      },
      deep: true//深度
    },
  },
  data() {
    return {
      selected: [],
      productId: 0,
      stockStr: "查看",
      selectedItem: {},
      userid: 0,
      id: 0,
      url: process.env.VUE_APP_OSS_URL,
      productCode: "",
      productName: "",
      specification: "",
      productUnit: "",
      boxQuantity: "",
      judgeDisplay: undefined,
      rowData: [],
      advanced_search_modal: false,
      product_category_name: "",
      level: 3,
      category_options: [],
      brandArray: [],
      product_brandName: '',
      product_taxRate_label: '',
      product_guaranteePeriodUnitLabel: '',
      product_status_label: '',
      excelSqlId: '',
      companyNameExcel: '',
      product_label_name: '',
      productEdit: '',
      readonly: 1,
      resultProduct: true,
      product_label_id_ext: '',

      boxProduct: {},
      newBoxQuantity: '',
      showBoxModal: '',
      clickExport: true,
      bbm_image_id: '',
      bbm_details: '',
      allchecked: false,
      indeterminate: false,
      isCheckedObj: {},
      table: [],
      checked: [],
    }
  },
  mounted() {
    this.isCheckedObj = {};
    this.table.forEach(element => {
      this.$set(this.isCheckedObj, 'index' + element.id, false)
    });
  },
  methods: {
    onSelectWHFromPeople() {
      let returnData = {}
      if (this.$refs.whSelect.getSelected().length > 0) {
        returnData = this.$refs.whSelect.getSelected()[0]
        this.product_label_id = returnData.label_id
        this.product_label_name = returnData.label_name
        this.$forceUpdate()
      }
    },
    btKeyUp(e) {
      e.target.value = e.target.value.replace(/[`~!@#$%^&*()_\-+=<>?:"{}|,.\/;'\\[\]·~！@#￥%……&*（）——\-+={}|《》？：“”【】、；‘’，。、]/g, "");
    },
    changeState: function (data) {
      if (confirm("确定设置无效吗?")) {
        const state = 1 - data.state
        store.dispatch('product/state', {id: data.id, state: state}).then(res => {
          data.state = state
          const memo = "商品设置无效"
          store.dispatch('modifylog/save', {
            object_type: 1,
            object_id: data.product_id,
            modify_content: memo
          }).then(res => {
          })
          this.refetchData()
        })
      }
    },


    showStock(item) {
      this.selectedItem = item
      this.productId = item.id
      this.$refs['stockModal'].show()
    },
    hideModal() {
      this.stockStr = this.$refs.stockShowModal.getStockStr();
      this.selectedItem['stockStr'] = this.stockStr;
    },
  },
  setup() {
    const toast = useToast()
    // Register module
    if (!store.hasModule('product')) store.registerModule('product', productStore)

    if (!store.hasModule('brand')) store.registerModule('brand', brandStore)

    if (!store.hasModule('modifylog')) store.registerModule('modifylog', modifylogStore)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule('product')) store.unregisterModule('product')

      if (store.hasModule('brand')) store.unregisterModule('brand')

      if (store.hasModule('modifylog')) store.unregisterModule('modifylog')
    })


    const copyRow = function (params) {
      this.$refs['copyRowDataModal'].show()
      this.rowData = params
    }

    const submitCopyRowData = async function (param) {
      if (this.productCode === undefined || this.productCode === "") {
        toast.error('商品编码(69码)必填!')
        return false
      }

      if (this.productName === undefined || this.productName === "") {
        toast.error('商品名称必填!')
        return false
      }

      if (this.productUnit === undefined || this.productUnit === "" || this.productUnit == null) {
        toast.error('基本单位必填!')
        return false
      }

      if (this.specification === undefined || this.specification === "") {
        toast.error('商品规格必填!')
        return false
      }

      if (this.boxQuantity === undefined || this.boxQuantity === "") {
        toast.error('单箱标准数量必填!')
        return false
      }


      this.rowData.code = ',' + this.productCode + ','
      this.rowData.name = this.productName
      this.rowData.specification = this.specification
      this.rowData.box_quantity = this.boxQuantity
      this.rowData.product_unit = this.productUnit

      this.rowData.label = this.productName.replace(this.specification, "").replace(" ", "")

      await store.dispatch('product/copyProduct', this.rowData).then(res => {
        if (res.data.code === 0) {
          toast.success('添加成功！')
          this.$refs['copyRowDataModal'].hide()
        } else {
          toast.error('添加失败！' + res.data.data)
        }
      })
      this.$refs['refListTable'].refresh()
    }

    const hideCopyRowModal = function () {
      this.productCode = ""
      this.productName = ""
      this.specification = ""
      this.productUnit = ""
      this.boxQuantity = ""
    }

    const advanced_search = function () {
      this.advanced_search_modal = this.advanced_search_modal ? false : true;
    }

    //yang
    const searchByConditionExcel = function () {
      this.clickExport = false;
      const params = {
        excelSqlId: this.excelSqlId,
      }
      store.dispatch('product/export', params)
          .then(response => {
            const data = response.data.data
            if (response.data.code == 0) {
              toast.success("导入成功")
              refetchData();
            } else {
              toast.error(data)
            }
          })

    }

    const onUploaded = function (id, attachment, result) {
      this.excelSqlId = result
    }
    const onSelectCategory = function () { //赋值回显
      this.product_category_id = this.$refs.categorySel.categoryId + "," + this.$refs.categorySel.categoryTowId + "," + this.$refs.categorySel.categoryThreeId;
      this.category_options = [];
      this.category_options.push(
          {
            "label": this.$refs.categorySel.categoryName,
            "category_id": this.$refs.categorySel.categoryId,
          },
          {
            "label": this.$refs.categorySel.categoryTowName,
            "category_id": this.$refs.categorySel.categoryTowId,
          },
          {
            "label": this.$refs.categorySel.categoryThreeName,
            "category_id": this.$refs.categorySel.categoryThreeId,
          },
      );
      var new_content = this.$refs.categorySel.categoryName + "/";
      new_content += this.$refs.categorySel.categoryTowName + "/";
      new_content += this.$refs.categorySel.categoryThreeName + "/";
      this.product_category_name = new_content;
    }

    const changeBrandId = function (event) {
      if (event != null) {
        this.product_brandId = event.brand_id
      }
    }

    const getBrand = function (d) {
      store.dispatch('brand/search').then(res => {
        let a = res.data.data.list
        for (let i = 0; i < a.length; i++) {
          d.push({"label": a[i].name, "brand_id": a[i].brand_id})
        }
      })
    }

    const changeSelect = function (key, event) {
      if (event != null) {
        if (key === 'tax_rate') {
          this.product_taxRate_label = getCode("tax_rate", event.value).label
          this.product_taxRate = getCode("tax_rate", event.value).value
        } else if (key === 'guarantee_period_unit') {
          this.product_guaranteePeriodUnitLabel = getCode('product_unit_period', event.value).label
          this.product_guaranteePeriodUnit = getCode('product_unit_period', event.value).value
        }
      } else {
        if (key === 'tax_rate') {
          this.product_taxRate = ''
          this.product_taxRate_label = ''
        } else if (key === 'guarantee_period_unit') {
          this.product_guaranteePeriodUnitLabel = ''
          this.product_guaranteePeriodUnit = ''
        }
      }
    }

    const changeProductStatus = function (event) {
      if (event != null) {
        this.product_status_label = event.label
        this.product_status = event.value
      }
    }

    const searchByCondition = function () {
      let min = parseInt(this.min_guarantee_period)
      let max = parseInt(this.max_guarantee_period)
      if (max < min || min < 0) {
        this.$toast.error("保质期范围最小值/最大值错误")
      } else {
        this.refetchData()
      }
    }

    const showProductLabel = function () {
      this.$refs['productLabel'].show()
    }

    const fromChildren = function (data, modal) {

      if (modal == 'from') {
        this.product_label_id = data.label_id
        this.product_label_name = data.label_name
        this.$forceUpdate()
      }
      this.$bvModal.hide('modal-single')
    }

    const selectProductLabel = function () {
      let returnData = {}
      let returnDataLabelId;
      let returnDataLabelName;
      if (this.$refs.labelSelect.getSelected().length > 0) {
        returnData = this.$refs.labelSelect.getSelected()[0]
        if (this.product_label_id == returnData.label_id) {
          this.resultProduct = true
        } else {
          this.resultProduct = false
        }

        if (returnData.label_name == '<空>') {
          returnDataLabelId = 0
          returnDataLabelName = returnData.label_name
        } else {
          returnDataLabelId = returnData.label_id
          returnDataLabelName = returnData.label_name
        }
        store.dispatch('product/save', {
          ...this.product_label_id_ext,
          label_id: returnDataLabelId,
          resultProduct: this.resultProduct,
          label_ym: true,
        }).then(res => {
          if (res.data.code === 0) {
            toast.success('数据已保存!')
            const memo = "修改标签 " + this.product_label_id_ext.ext.name.label_name + "->" + returnDataLabelName
            store.dispatch('modifylog/save', {
              object_type: 1,
              object_id: this.product_label_id_ext.product_id,
              modify_content: memo
            }).then(res => {
            })
            this.refetchData()
          } else {
            toast.error(res.data.data)
          }
        })
      }
    }
    const showProductLabelExt = function (item) {
      this.product_label_id_ext = item
      if (this.readonly == 0) {
        this.$refs['productLabelExt'].show()
      } else {
        toast.error("对不起，您没有修改标签的权限")
      }
    }


    const resetCondition = function () {
      this.product_id = ''
      this.product_code = ''
      this.product_name = ''
      this.product_specification = ''
      this.product_status_label = ''
      this.product_status = ''
      this.product_boxQuantity = ''
      this.product_category_name = ''
      this.product_category_id = ''
      this.product_brandName = ''
      this.product_brandId = ''
      this.product_taxRate = ''
      this.product_taxRate_label = ''
      this.product_guaranteePeriodUnit = ''
      this.product_guaranteePeriodUnitLabel = ''
      this.min_guarantee_period = ''
      this.max_guarantee_period = ''
      this.product_label_name = ''
      this.product_label_id = ''

      this.refetchData()
    }


    const saveProductBox = function () {
      if (this.newBoxQuantity === '' || this.newBoxQuantity === undefined) {
        toast.error("单箱标准数量必填!")
        return false
      }
      if (this.boxProduct.box_quantity == this.newBoxQuantity) {
        toast.error("修改后的单箱标准数量不能和修改前的相同!")
        return false
      }
      const cRegExp = /^[1-9]\d*$/
      if (!cRegExp.test(this.newBoxQuantity)) {
        toast.error('单箱标准数量必须是正整数!')
        return false
      }
      store.dispatch('product/saveProductBox', {
        id: this.boxProduct.product_id,
        newBoxQuantity: this.newBoxQuantity
      }).then(res => {
        if (res.data.code == 0) {
          toast.success("修改成功")
          refetchData()
        } else {
          toast.error(res.data.data)
          refetchData()
        }
      })
      this.$refs['productBoxModal'].hide()
      this.$forceUpdate()
    }

    const closeModal = function (val) {
      if (val == "success") this.$refs['productBoxModal'].hide()
    }

    //=====首图
    const addFirstPicture = function (item) {
      this.bbm_image_id = item.bbm_image_id
      this.productId = item.id
      this.$refs['addFirstPicture'].show()
    }

    const uploadPicture = function (id, attachment, result) {
      this.bbm_image_id = result
    }

    const onAddPicture = function () {
      store.dispatch('product/uploadPicture',
          {id: this.productId, bbmImageId: this.bbm_image_id}).then(res => {
        toast.success("添加成功")
        refetchData()
      })
    }

    //=====详情图
    const addDetails = function (item) {
      if (item.bbm_image_id == null) {
        toast.error("请先上传首图")
        return false
      }
      this.bbm_details = item.bbm_details
      this.productId = item.id
      this.$refs['addDetails'].show()
    }

    const uploadDetails = function (id, attachment, result) {
      this.bbm_details = result
    }

    const onAddDetails = function () {
      store.dispatch('product/uploadDetails',
          {id: this.productId, bbmDetails: this.bbm_details}).then(res => {
        toast.success("添加成功")
        refetchData()
      })
    }

    const pushBBMall = function (ids) {
      store.dispatch('product/pushBBMall', {ids: ids}).then(res => {
        toast.success(res.data.data)
        refetchData()
      })
    }

    const pushBBMallBatch = function () {
      console.log(this.checked)
      let ids = []
      for (let i = 0; i < this.checked.length; i++) {
        if (this.checked[i].bbm_item_id == null && this.checked[i].bbm_sku_id == null) {
          ids.push(this.checked[i].id)
        } else {
          toast.error(this.checked[i].id + "已推送，请勿重复推送")
          refetchData()
          return false
        }
      }
      store.dispatch('product/pushBBMall', {ids: ids.toString()}).then(res => {
        toast.success(res.data.data)
        refetchData()
      })
    }

    // 点击全选按钮
    const toggleAll = function (checked) {
      this.checked = []
      this.table.forEach(element => {
        if (checked) {
          this.checked.push(element);
        }
        this.isCheckedObj['index' + element.id] = checked;
      });
      this.$forceUpdate()
    }
    // 复选框点击选中与取消
    const checkboxClick = function (item) {
      if(this.isCheckedObj['index' + item.id]){
        //勾选
        this.checked.push(item)
      }else {
        //取消勾选
        for(let i=0;i<this.checked.length;i++){
          //在已勾选数组中找出要取消勾选的
          if(this.checked[i].id == item.id){
            this.checked.splice(i,1)
          }
        }
      }
      this.$forceUpdate()
    }

    const {
      searchList,
      tableColumns,
      limit,
      start,
      listTotals,
      dataMeta,
      limitOptions,
      searchKey,
      orderBy,
      isSortDirDesc,
      refListTable,
      refetchData,

      // 查询条件
      product_code,
      product_id,
      product_name,
      product_specification,
      product_status,
      product_boxQuantity,
      product_category_id,
      product_brandId,
      product_taxRate,
      product_guaranteePeriodUnit,
      min_guarantee_period,
      max_guarantee_period,
      product_label_id,
    } = productUseList()

    return {
      searchList,
      tableColumns,
      limit,
      start,
      listTotals,
      dataMeta,
      limitOptions,
      searchKey,
      orderBy,
      isSortDirDesc,
      refListTable,
      refetchData,
      addFirstPicture,
      uploadPicture,
      onAddPicture,
      addDetails,
      uploadDetails,
      onAddDetails,
      pushBBMall,
      pushBBMallBatch,
      toggleAll,
      checkboxClick,

      // Filter
      avatarText,

      // UI
      getCodeLabel,
      getCode,
      getCodeColor,
      getCodeOptions,
      copyRow,
      submitCopyRowData,
      hideCopyRowModal,
      advanced_search,
      onSelectCategory,
      changeBrandId,
      getBrand,
      changeSelect,
      searchByCondition,
      resetCondition,
      changeProductStatus,
      searchByConditionExcel,
      onUploaded,
      showProductLabel,
      fromChildren,
      showProductLabelExt,
      saveProductBox,
      closeModal,


      // 查询条件
      product_code,
      product_id,
      product_name,
      product_specification,
      product_status,
      product_boxQuantity,
      product_category_id,
      product_brandId,
      product_taxRate,
      product_guaranteePeriodUnit,
      min_guarantee_period,
      max_guarantee_period,
      product_label_id,
      selectProductLabel,
    }
  },

  created() {
    const userData = getUserData()
    this.userid = userData.user_id

    if (userData.role_id == 14) {
      this.readonly = 0
    }

    this.getBrand(this.brandArray)
    this.productEdit = getCodeLabel("setting", "product_edit")
    let array = this.productEdit.split(",")
    for (let i = 0; i < array.length; i++) {
      if (userData.user_id == array[i]) {
        this.readonly = 0
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
